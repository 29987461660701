.footer {
    background-color: #333;
    overflow: hidden;
    position: felx; /* Position fixe */
    bottom: 0; /* En bas de la page */
    width: 100%; /* Largeur pleine */
    height: auto; /* Hauteur pleine */

  }
  
.footerbtn {
    float: right;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    height: auto;
  }
  
  .footerp {
    float: left;
    color: #ddd;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }

.footebtn:hover {
    background-color: #ddd;
    color: black;
  }
  
.footebtn.active {
    background-color: #4CAF50;
    color: #ddd;
  }