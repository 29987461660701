.search-form {
    width: 600px; /* Largeur */
    margin: 0 auto; /* Centrage horizontal */
    padding: 20px; /* Espacement intérieur */
    display : grid;
    grid-template-columns: repeat(7, 1fr); /* 7 colonnes, taille égale */
    background-color: aliceblue;
    border-radius: 10%;
  }
  
  /* Style des labels */
.label {
    display: block; /* Affichage en bloc */
    margin-bottom: 10px; /* Espacement en bas */
    font-size: 18px; /* Taille de police */
    align-items: center; /* Alignement horizontal */
    display: flex;
  }
  
  /* Style des champs de saisie */
.inputtextsmall {
    width: 140px; /* Largeur pleine */
    padding: 12px 20px; /* Espacement intérieur */
    margin-bottom: 10px; /* Espacement en bas */
    border: 1px solid #ccc; /* Bordure */
    border-radius: 4px; /* Arrondi des bords */
    box-sizing: border-box; /* Prise en compte de la bordure et de l'espacement dans les dimensions */
    font-size: 16px; /* Taille de police */
    grid-column: span 2; /* Prendre toutes les colonnes */
  }

  .item-large-input {
    width: 100%; /* Largeur pleine */
    
    padding: 12px 20px; /* Espacement intérieur */
    margin-bottom: 20px; /* Espacement en bas */
    border: 1px solid #ccc; /* Bordure */
    border-radius: 4px; /* Arrondi des bords */
    box-sizing: border-box; /* Prise en compte de la bordure et de l'espacement dans les dimensions */
    font-size: 16px; /* Taille de police */
    grid-column: span 7; /* Prendre toutes les colonnes */
  }

  .item-large-label {
    display: block; /* Affichage en bloc */
    margin-bottom: 10px; /* Espacement en bas */
    font-size: 18px; /* Taille de police */
    grid-column: span 7; /* Prendre toutes les colonnes */
  }
  .item-large-btn {
    background-color: #4CAF50; /* Couleur de fond */
    color: white; /* Couleur du texte */
    padding: 14px 20px; /* Espacement intérieur */
    border: none; /* Pas de bordure */
    cursor: pointer; /* Cursor de souris en forme de main */
    width: 100%; /* Largeur pleine */
    font-size: 18px; /* Taille de police */
    transition: all 0.3s ease-out; /* Animation de transition */
    border-radius: 5px;
    grid-column: span 7; /* Prendre toutes les colonnes */
  }
  .item-large-btn:hover {
    background-color: #3e8e41; /* Couleur de fond */
    transform: scale(1.05); /* Augmenter la taille de 5% */
  }
  .message-erreur{
    margin: 0px;
  }
  table {
    width: 100%; /* Largeur pleine */
    border-collapse: collapse; /* Supprimer les espacements entre les cellules */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Ajouter une ombre */
    margin-top : 30px;
  }
  
  th, td {
    border: 1px solid #ddd; /* Bordure */
    padding: 12px; /* Espacement intérieur */
    text-align: left; /* Alignement du texte */
    transition: all 0.3s ease-out; /* Animation de transition */
  }

  td:hover {
    background-color: #f2f2f2; /* Couleur de fond lors du survol */
    transform: scale(1.05); /* Augmenter la taille de 5% */
  }
  
  th {
  background-color: #3ea4b6; /* Couleur de fond des en-têtes */
  color: white; /* Couleur du texte */
  font-weight: bold; /* Gras */
  }

  .hidden{
    display: none; /* Cacher le contenu */
  }
  .visible{
    display: block; /* Cacher le contenu */
  }
  