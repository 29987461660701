
html, body, #root {
  margin: 0; /* Retrait extérieur */
  padding: 0; /* Retrait intérieur */
  width: 100%; /* Largeur */
  height: 100%; /* Hauteur */
  display: block;
  background-color: rgb(179, 190, 176);
}


.nav {
    background-color: #333;
    overflow: hidden;
    height: auto;
  }
  
.navbtn {
    float: right;
    color: #ddd;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .navtitre {
    float: left;
    color: #ddd;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    font-weight: bold;
  }

.navbtn:hover {
    background-color: #ddd;
    color: black;
  }
  
.navbtn.active {
    background-color: #4CAF50;
    color: #ddd;
  }