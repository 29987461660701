.div-form {
  display: flex; /* Afficher en ligne */
  align-items: center; /* Alignement horizontal */
  height: 80%; /* Hauteur pleine */
}

.login-form {
    width: 300px; /* Largeur */
    margin: 0 auto; /* Centrage horizontal */
    padding: 20px; /* Espacement intérieur */
    background-color: #f9f9f9; /* Couleur de fond */
    border: 1px solid #ccc; /* Bordure */
    border-radius: 5px; /* Arrondi des bords */
    text-align: center; /* Alignement du texte */
  }
  /* Style des labels */
.login-form label {
    display: block; /* Affichage en bloc */
    margin-bottom: 10px; /* Espacement en bas */
    font-size: 18px; /* Taille de police */
  }
  /* Style des champs de saisie */
.login-form input[type="email"],
.login-form input[type="password"] {
    width: 100%; /* Largeur pleine */
    padding: 12px 20px; /* Espacement intérieur */
    margin-bottom: 20px; /* Espacement en bas */
    border: 1px solid #ccc; /* Bordure */
    border-radius: 4px; /* Arrondi des bords */
    box-sizing: border-box; /* Prise en compte de la bordure et de l'espacement dans les dimensions */
    font-size: 16px; /* Taille de police */
}
.login-form-btn {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}