.divaccueil{
    text-align: center;
    line-height: 1.5; /* Hauteur de ligne */
    color: rgb(29, 29, 29); /* Couleur de texte */
    margin: 0; /* Espacement extérieur */
}
.btnaccueil{
  background-color: rgb(65, 131, 215); /* Couleur de fond */
  color: white; /* Couleur du texte */
  padding: 15px 32px; /* Espacement intérieur */
  text-align: center; /* Alignement du texte */
  text-decoration: none; /* Retrait du texte */
  display: inline-block; /* Affichage en ligne */
  font-size: 16px; /* Taille de police */
  margin: 20px 10px; /* Espacement extérieur */
  cursor: pointer; /* Curseur en forme de main */
  border-radius: 4px; /* arrondi des bords */
  transition: all 0.2s ease-in-out; /* animation */
}

.btnaccueil:hover{
    background-color: rgb(50, 96, 156); /* couleur de fond lors de la surbrillance */
}